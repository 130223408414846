@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/*====================== Utiltities ============================= */
@layer utilities {
  .placeholder-sm {
    &::placeholder {
      font-size: 0.8rem;
    }
  }
}

/*====================== Header ============================= */

.header-nav {
  .header-nav__menu {
    display: none;
    ul.header-nav__menu__custom {
      display: block;
    }
    &.is-active {
      display: block;
    }
  }
}

/*====================== Dashboard ============================= */
.Dashboard {
  font-family: "Fira sans", sans-serif;
}
.dashboard__topbar {
  .topbar__search {
    .topbar__search__input {
      &:focus {
        outline: 0;
        box-shadow: none !important;
      }
    }
  }
}

.dashboard__sidebar {
  position: -webkit-sticky; /* for Safari users */
  position: sticky;
  top: 0px;
  padding-top: 50px;
  padding-right: 10px;
  .dashboard__sidebar__header {
    text-align: -webkit-left;
    padding-left: 30px; 
    .dashboard__sidebar__header__circle {
      height: 50px;
    }
  }
  .dashboard__sidebar__menu {
    li {
      text-align: -webkit-left;
      padding-left: 30px;
    }
  }
}
